import { isNil } from 'ramda';
import { apolloClient } from '@/plugins/apollo-client';
import { onBeforeUnmount } from 'vue';
import { getCurrentInstance } from 'vue';

import { TASKS_QUERY } from '../../compositions/graphql';
import { usePatchTask, useActivateTask } from '../../compositions/useTasks';

export const validateStatusReflection = (statusReflection) => {
  let validated;
  statusReflection.validate((valid) => (validated = valid));
  return validated;
};

export const useHandleTaskUpdate = () => {
  const { patchTask } = usePatchTask();
  const { activeTask } = useActivateTask();
  return {
    handleTaskUpdate: (updateData, subTasks) => {
      patchTask({
        taskId: updateData.task.id,
        patchParams: {
          data: {
            ...updateData.task.data,
            comment: updateData.comment,
            ...(subTasks && { subTasks }),
          },
        },
      });
      if (!isNil(updateData.activeAt)) {
        activeTask({
          taskId: updateData.task.id,
          activeParams: {
            activeAt: updateData.activeAt,
          },
        });
      }
    },
  };
};

const timeoutIds = [];
const sleep = async (ms) => new Promise((resolve) => timeoutIds.push(setTimeout(resolve, ms)));

export const useContinueToNextTask = () => {
  const { $router } = getCurrentInstance().proxy;
  onBeforeUnmount(() => timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId)));
  return {
    continueToNextTask: async (businessId, task) => {
      const nextTask = await getNextTask(businessId, task);
      if (isNil(nextTask)) {
        if (task.type === 'balanceAlignment') await sleep(5000);
        $router.push({ name: getTab(task.type) });
      } else {
        $router.push({ name: getRouteName(task.type), params: { taskId: nextTask.id } });
      }
    },
  };
};

const getNextTask = async (businessId, task) => {
  const { data } = await apolloClient.query({
    query: TASKS_QUERY,
    variables: {
      businessId,
      completed: false,
      domains: task.domain,
      type: task.type,
      limit: 2,
      offset: 0,
    },
  });
  return data.tasksNew2.nodes.filter(({ id }) => id !== task.id)[0];
};

export const TASK_TYPE = {
  SUPPLIER_CREATION: 'supplierCreation',
  HANDLE_RECONCILIATION: 'handleReconciliation',
  BALANCE_ALIGNMENT: 'balanceAlignment',
  UNCERTAIN_BILLING: 'uncertainBilling',
};

const getTab = (type) => {
  switch (type) {
    case TASK_TYPE.SUPPLIER_CREATION:
      return 'supplier-create-tasks';
    case TASK_TYPE.HANDLE_RECONCILIATION:
      return 'reconciliation-tasks';
    case TASK_TYPE.BALANCE_ALIGNMENT:
      return 'balance-alignment-tasks';
    case TASK_TYPE.UNCERTAIN_BILLING:
      return 'uncertain-billing-tasks';
    default:
      return '';
  }
};

export const getRouteName = (taskType) => {
  switch (taskType) {
    case TASK_TYPE.SUPPLIER_CREATION:
      return 'supplierTasks';
    case TASK_TYPE.HANDLE_RECONCILIATION:
      return 'reconciliationTask';
    case TASK_TYPE.BALANCE_ALIGNMENT:
      return 'balanceAlignmentTask';
    case TASK_TYPE.UNCERTAIN_BILLING:
      return 'uncertainBillingTask';
    default:
      return '';
  }
};
