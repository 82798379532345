<template>
  <div>
    <div class="dropdown">
      <button
        ref="dropdownRef"
        class="btn dropdown-button dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
      >
        <span :class="selectedItems.length ? 'active' : ''">
          {{ searchLabel }}
          <button v-if="!!selectedItems.length" class="btn btn-link close-button" @click="onClearSelection">
            <CloseIcon class="close-button-icon" />
          </button>
          <span class="chevron-icon">
            <ChevronLgIcon />
          </span>
        </span>
      </button>
      <ul ref="dropdownMenuRef" class="dropdown-menu dropdown-menu-end">
        <li v-for="item in filteredOptions" :key="item.id">
          <div
            class="dropdown-item container"
            :class="!item.isPermitted ? 'disabled-item' : ''"
            @click="
              (e) => {
                if (!item.isPermitted) return;
                onItemClicked(item.id);
              }
            "
          >
            <div class="item-content">
              <input
                :disabled="!item.isPermitted"
                class="form-check-input"
                :class="!item.isPermitted ? 'disabled-input-color' : ''"
                type="checkbox"
                :checked="item.isChecked"
              />
              <span :class="!item.isPermitted ? 'disabled-text-color' : ''">
                {{ item.name }}
              </span>
            </div>
            <el-tooltip
              :disabled="item.isPermitted"
              :content="$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted')"
              placement="top"
            >
              <LockInvIcon v-if="!item.isPermitted" class="lock-icon" fill="#C4C6CF" :size="12" />
            </el-tooltip>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { LockInvIcon } from '@/assets/icons';

import { ChevronLgIcon, CloseIcon } from '@/assets/icons';

export default {
  components: { ChevronLgIcon, CloseIcon, LockInvIcon },
  props: {
    initialSelectedIds: {
      type: Array,
      default: () => [],
    },
    options: {
      // Options should be an array of objects, i.e: [{ id, name }]
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Type',
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const dropdownMenuRef = ref();
    const dropdownRef = ref();
    const selectedItems = ref(props.options.filter((o) => props.initialSelectedIds.includes(o.id)));

    const checkedSelected = (item) => ({
      ...item,
      isChecked: selectedItems.value.find((selectedItem) => selectedItem.id === item.id),
    });

    const filteredOptions = computed(() => {
      return props.options.map(checkedSelected);
    });

    const onItemClicked = (id) => {
      const alreadySelectedItem = selectedItems.value.find((item) => item.id === id);
      if (alreadySelectedItem) {
        selectedItems.value = selectedItems.value.filter((item) => item.id !== id);
      } else {
        const item = props.options.find((item) => item.id === id);
        selectedItems.value.push(item);
      }

      emit('change', [...selectedItems.value]);
    };

    const searchLabel = computed(() => {
      const label = props.label;
      if (!selectedItems.value.length) return label;

      if (selectedItems.value.length === 1) {
        return `${label}: ${selectedItems.value[0].name}`;
      }

      return `${label}: (${selectedItems.value.length})`;
    });

    const onClearSelection = () => {
      dropdownMenuRef.value?.classList?.remove('show');
      dropdownRef.value?.classList?.remove('show');

      selectedItems.value = [];

      emit('change', []);
    };

    return {
      filteredOptions,
      onItemClicked,
      selectedItems,
      searchLabel,
      onClearSelection,
      dropdownMenuRef,
      dropdownRef,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import './style';

.disabled-input-color {
  background-color: #f3f3f4;
}
.disabled-item:hover {
  cursor: default;
  background-color: #fff;
}
.disabled-text-color {
  color: #c4c6cf;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-content {
  display: flex;
  align-items: center;
}
.lock-icon {
  margin-left: auto;
  [dir='rtl'] & {
    margin-right: auto;
    margin-left: 0;
  }
}

.form-check-input {
  margin-left: 0;
  margin-right: 0.75rem;

  [dir='rtl'] & {
    margin-left: 0.75rem;
    margin-right: 0;
  }
  margin-top: 0;
}
</style>
